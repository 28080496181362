import { Controller } from 'stimulus';
import * as Turbo from '@hotwired/turbo';

export default class extends Controller {
  static values = { forceVisit: Boolean };

  connect() {
    this.element.querySelectorAll('.nav-item').forEach((navItem) => {
      navItem.addEventListener('click', () => {
        this.onTabClick(navItem);
      });
    });
  }

  onTabClick(tab) {
    // get first element with .nav-link class
    let navLink = tab.querySelector('.nav-link');
    if (navLink && navLink.id) {
      window.history.replaceState(
        null,
        null,
        `?tab=${navLink.id.replace('-tab', '')}`
      );
    }

    let dropdownItem = tab.querySelector('.dropdown-item');
    if (dropdownItem && dropdownItem.id) {
      window.history.replaceState(
        null,
        null,
        `?tab=${dropdownItem.id.replace('-tab', '')}`
      );
    }

    if (this.hasForceVisitValue && this.forceVisitValue) {
      Turbo.visit(window.location.href, { action: 'replace' });
    }
  }
}
