import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['section'];
  static values = { shownSection: String };

  revealSection({ params: { section } }) {
    this.#revealSection(section);
  }

  #revealSection(section) {
    this.sectionTargets.forEach((sectionTarget) => {
      if (sectionTarget.dataset.sectionName === section) {
        sectionTarget.classList.remove('d-none');
      } else {
        sectionTarget.classList.add('d-none');
      }
    });
    this.shownSectionValue = section;
  }
}
